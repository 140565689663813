* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

@font-face {
  font-family: 'Didot';
  src: url('../public/fonts/Didot.woff2') format('woff2'),
    url('../public/fonts/Didot.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Didot';
  src: url('../public/fonts/Didot-Bold.woff2') format('woff2'),
    url('../public/fonts/Didot-Bold.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: 'Didot';
  src: url('../public/fonts/Didot-Italic.woff2') format('woff2'),
    url('../public/fonts/Didot-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Caslon';
  src: url('../public/fonts/Caslon.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Didot', 'Times New Roman', serif;
  font-size: 8px;
  background: white;
  color: black;
}

.container {
  height: 100dvh;
  width: 100dvw;
  margin: 0 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
}

section {
  min-height: 100%;
  max-height: fit-content;
  width: 100%;
  scroll-snap-align: start;
}

.landing {
  display: grid;
  grid-template-rows: 6fr 1fr;
  height: 100%;
  width: 100%;
}

.flower {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flower img {
  width: 40%;
  display: inline-block;
  min-width: 100px;
  max-width: 600px;
}

.logo {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 100%;
}


.text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.text img {
  width: 65%;
  display: inline-block;
  min-width: 100px;
  max-width: 800px;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-content: center;
}

.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero video {
  width: 100%;
  object-fit: fill;
  padding: 0;
  margin: 0;
  line-height: 0;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100dvw;
}

.menu {
  display: grid;
  grid-gap: 2.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.menu h1 {
  font-family: 'Caslon', 'Times New Roman', serif;
  font-size: 2rem;
  font-weight: bold;
}

.menu h2 {
  font-family: 'Caslon', 'Times New Roman', serif;
  font-size: 1.5rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1.1rem;
}

.menu h4 {
  font-family: 'Caslon', 'Times New Roman', serif;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 1rem;
  padding: 1rem 0;
}

.menu h3 {
  font-family: 'Caslon', 'Times New Roman', serif;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 1.25rem;
}

.menu a {
  font-family: 'Caslon', 'Times New Roman', serif;
  font-size: 1.5rem;
  font-weight: normal;
  text-decoration: none;
  color: black;
  transition: color 0.5s;
}

.menu a:hover {
  color: blue;
}

.portrait {
  display: none;
}

.landscape {
  display: block;
  height: 100dvh;
}

.landscape img {
  height: 100%;
}

.menu img {
  width: 75%;
  margin-left: 12.5%;
}

@media only screen and (max-width: 800px) {
  .logo img {
    height: auto;
    width: 100%;
  }
}

/* MOBILE STYLES */

@media only screen and (max-width: 576px) {

  section {
    height: 100dvh;
    align-content: center;
  }

  .landscape img {
    height: auto;
    width: 100%;
  }

  .logo img {
    width: 100%;
    height: auto;
  }

  .landing {
    display: grid;
    grid-template-rows: 7fr 1fr;
    height: 100%;
    width: 100%;
  }

  .flower img {
    width: 80%;
  }

  .text img {
    width: 80%;
  }

  .menu {
    display: grid;
    grid-gap: 3rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .menu h2 {
    font-family: 'Caslon', 'Times New Roman', serif;
    font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.35rem;
    line-height: 2rem;
  }

  .menu h4 {
    font-family: 'Caslon', 'Times New Roman', serif;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0.25rem;
    width: 98%;
    margin-left: 1%;
    overflow: hidden;
    line-height: 2rem;
  }

  .menu h3 {
    font-family: 'Caslon', 'Times New Roman', serif;
    font-size: 1rem;
    font-weight: normal;
    letter-spacing: 0.25rem;
    line-height: 2rem;
  }

  .menu a {
    font-family: 'Caslon', 'Times New Roman', serif;
    font-size: 1rem;
    font-weight: normal;
    text-decoration: none;
    color: black;
    transition: color 0.5s;
    line-height: 2rem;
  }

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    align-content: center;
  }


}

/* LANDSCAPE */

@media only screen and (max-height: 500px) and (orientation: landscape) {

  .landing {
    display: grid;
    height: 100%;
    width: 100%;
  }

  .flower img {
    width: 30%;
  }

  .text img {
    width: 40%;
  }

  .menu {
    display: grid;
    grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    text-align: center;
    height: 100%;

    padding-top: 1rem;
  }

  .menu h1 {
    font-size: 1.5rem;
  }

  .menu h2 {
    font-size: 1.5rem;
  }

  .menu h3 {
    font-size: 1.5rem;
  }

  .menu a {
    font-family: 'Caslon', 'Times New Roman', serif;
    font-size: 1.5rem;
    font-weight: normal;
    text-decoration: underline;
    color: blue;
  }
}